import React from 'react'

export default function InstagramSection () {
  return (
    <div className='instagramSection'>
      <img src={require('../../../images/insta-section-bg.png')} alt='img' />
      <a href='/'> #Life@NCG </a>
    </div>
  )
}
