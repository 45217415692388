import React, { Component } from 'react';
import ReactTable from 'react-table';
import { shortlistApplicant, removeApplicant } from '../../actions/jobActions';
import { getAllUserJobs } from '../../actions/userJobActions';
import { getAllUserTests } from '../../actions/userTestActions';
import { getTests } from '../../actions/testActions';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap4-modal';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { sendEmailInvitation, sendTestInvitation, getUserJobs } from '../../actions/jobActions';
import isEmpty from '../../validation/is-empty';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ReactQuill from 'react-quill';

class Applicants extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			invitationList: [],
			show: false,
			body: '',
			defaultInterviewText: '',
			defaultTestText: '',
			showTest: false,
			userJobs: [],
			checkedBox: false,
			tests: [],
			topCandidatesList: [],
			showTopCandidates: false
		};
		this.handleClose = this.handleClose.bind(this);
		this.handleTestTextBoxChange = this.handleTestTextBoxChange.bind(this);
		this.handleInterviewTextBoxChange = this.handleInterviewTextBoxChange.bind(this);
		this.getTestInfo = this.getTestInfo.bind(this);
		this.getJobTestInfo = this.getJobTestInfo.bind(this);
		this.shortlistUser = this.shortlistUser.bind(this);
	}

	shortlistUser(job_id, applicant_id) {
		this.props.shortlistApplicant(job_id, applicant_id);
	}

	async componentDidMount() {
		await this.props.getAllUserJobs();
		await this.props.getAllUserTests();
		await this.props.getTests();
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.job) {
			this.setState({
				defaultInterviewText: `<p>Dear Candidate,</p> <br/> <p>As a result of your application for the position of <strong> ${this
					.props.job.title}</strong>, I would like to invite you to attend an interview on </p>`,
				defaultTestText: `<p>Dear Candidate,</p> <br/> <p>As a result of your application for the position of <strong> ${this
					.props.job.title}</strong>, I would like to invite you to take a Test on </p>`
			});
		}
		if (nextProps.allUserJobs) {
			this.setState({
				userJobs: nextProps.allUserJobs.filter((auj) => auj.job == nextProps.job._id)
			});
		}
		// console.log('NP:', nextProps);
	}

	deleteUser(job_id, applicant_id) {
		confirmAlert({
			title: 'Confirm to delete',
			message: 'Are you sure?',
			buttons: [
				{
					label: 'Yes',
					onClick: () => this.props.removeApplicant(job_id, applicant_id)
				},
				{
					label: 'No',
					onClick: () => ''
				}
			]
		});
	}

	deleteUserAndMarkTop(job_id) {
		const top_applicants = this.state.topCandidatesList;
		let all_applicants = this.props.applicants;
		let to_delete = [];
		for (const applicant_id of top_applicants) {
			all_applicants = all_applicants.filter((applicant) => {
				console.log('app', applicant);
				return String(applicant._id) !== String(applicant_id) ? applicant._id : false;
			});
		}
		all_applicants.forEach((applicant) => {
			to_delete.push(applicant._id);
		});
		confirmAlert({
			title: 'This will delete all other candidates',
			message: 'Are you sure?',
			buttons: [
				{
					label: 'Yes',
					onClick: () => {
						this.props.removeApplicant(job_id, to_delete);
					}
				},
				{
					label: 'No',
					onClick: () => ''
				}
			]
		});
	}

	selectForInvitation(e) {
		let list = this.state.invitationList;
		let value = e.target.checked;
		let id = e.target.name;

		if (value) {
			list.push(id);
			this.setState({ invitationList: list });
		} else {
			list = list.filter((i) => i != id);
			this.setState({ invitationList: list });
		}
	}

	selectApplicants(e) {
		let list = this.state.invitationList;
		let value = e.target.checked;
		let id = e.target.name;

		if (value) {
			list.push(id);
			this.setState({ invitationList: list });
		} else {
			list = list.filter((i) => i != id);
			this.setState({ invitationList: list });
		}
		// this.setState({ checkedBox: !this.state.checkedBox })
	}

	selectTopCandidates(e) {
		let list = this.state.topCandidatesList;
		console.log('TCL: Applicants -> selectTopCandidates -> list', list);
		console.log('TCL: Applicants -> selectTopCandidates -> target', e.target);
		let value = e.target.checked;
		let id = e.target.name;

		if (value) {
			list.push(id);
			this.setState({ topCandidatesList: list });
		} else {
			list = list.filter((i) => i != id);
			this.setState({ topCandidatesList: list });
		}
	}

	handleClose() {
		this.setState({ show: false, showTest: false, showTopCandidates: false });
	}

	handleShow() {
		this.setState({ show: true });
	}

	handleTestShow() {
		this.setState({ showTest: true });
	}

	handleShowTopCandidates() {
		this.setState({ showTopCandidates: true });
	}

	sendInvitation(e) {
		e.preventDefault();
		let applicantEmails = this.props.applicants
			.filter((ap) => this.state.invitationList.indexOf(ap._id) >= 0)
			.map((a) => a.email);
		let applicantIds = this.props.applicants
			.filter((ap) => this.state.invitationList.indexOf(ap._id) >= 0)
			.map((a) => a._id);
		let obj = {
			body: this.state.defaultInterviewText,
			emails: applicantEmails,
			job: this.props.job,
			applicantIds: applicantIds
		};
		this.props.sendEmailInvitation(obj);
		this.handleClose();
		this.setState({ invitationList: [] });
	}

	sendTestInvitation(e) {
		e.preventDefault();
		let applicantEmails = this.props.applicants
			.filter((ap) => this.state.invitationList.indexOf(ap._id) >= 0)
			.map((a) => a.email);
		let applicantIds = this.props.applicants
			.filter((ap) => this.state.invitationList.indexOf(ap._id) >= 0)
			.map((a) => a._id);
		let obj = {
			body: this.state.defaultTestText,
			emails: applicantEmails,
			job: this.props.job,
			applicantIds: applicantIds
		};
		this.props.sendTestInvitation(obj);
		this.handleClose();
		this.setState({ invitationList: [] });
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	handleInterviewTextBoxChange(value) {
		this.setState({ defaultInterviewText: value });
	}
	handleTestTextBoxChange(value) {
		this.setState({ defaultTestText: value });
	}

	getTestInfo(applicant, job) {
		return this.props.allUserTests.filter((ut) => ut.user == applicant._id && ut.test == job.test);
	}

	getJobTestInfo(job) {
		return this.props.tests.filter((t) => t._id === job.test);
	}

	getUserJobInfo(applicant, job) {
		if (!isEmpty(this.props.allUserJobs)) {
			return this.props.allUserJobs.filter((j) => j.user === applicant._id && j.job === job._id);
		} else {
			return [];
		}
	}

	getTrProps = (state, rowInfo, instance) => {
		if (rowInfo && state.allUserTests) {
			// if (_.isEmpty(JobInfo.testLink) && JobInfo.interviewInvitation === false) {
			//   color = '#ffffff';
			// } else
			// console.log('TCL: Applicants -> getTrProps -> JobInfo', JobInfo);
			// var color = '#ffffff';
			// const JobInfo = this.getUserJobInfo(rowInfo.row, state.job)[0];
			// if (JobInfo.interviewInvitation === true) {
			// 	color = '#a5c5ad';
			// } else if (!_.isEmpty(JobInfo.testLink)) {
			// 	color = '#007bff40';
			// }

			// console.log("TCL: getTrProps -> rowInfo.row", rowInfo.row)
			// let userTest = this.getTestInfo(rowInfo.row, state.job)
			// console.log("Test Link", this.getUserJobInfo(rowInfo.row, state.job)[0])
			return {
				value: 10,
				// style: {
				// 	background: !isEmpty(this.getUserJobInfo(rowInfo.row, state.job)[0].testLink)
				// 		? '#007bff40'
				// 		: this.getUserJobInfo(rowInfo.row, state.job)[0].interviewInvitation === true ? '#a5c5ad' : ''
				// }
			};
		}
		return {};
	};

	changeStatus(status) {
		if (this.props.match.params.id) {
			this.props.changeStatus(this.props.match.params.id, status);
		}
	}

	render() {
		const { applicants, job, allUserJobs, allUserTests, tests, row } = this.props;
		const { userJobs } = this.state;
		const columns = [
			{
				id: 'Name',
				Header: 'Name',
				accessor: (d) => `${d.firstName} ${d.middleName} ${d.lastName}`
			},
			{
				Header: 'Email',
				accessor: 'email'
			},
			{
				Header: 'Cell #',
				accessor: 'phoneNumber'
			},
			{
				id: 'Company',
				header: 'Company',
				accessor: (d) => `${!isEmpty(d.career) && d.career[0].company}`
			},
			{
				id: 'Education',
				Header: 'CGPA',
				accessor: (d) => `${!isEmpty(d.education) && d.education[0].cgpa}`
			},
			{
				Header: 'Experience',
				accessor: 'totalExperience'
			},
			{
				Header: 'Score',
				accessor: '_id',
				Cell: (row) => {
					let userTest = {};
					let test = {};
					if (allUserTests) {
						userTest = this.getTestInfo(row.row, job)[0];
						test = this.getJobTestInfo(job)[0];
					}
					return (
						<div>{userTest && userTest.score && test && `${userTest.score} / ${test.totalQuestions}`}</div>
					);
				}
			},
			{
				Header: 'Resume',
				accessor: 'resume',
				Cell: (row) => (
					<a className="editOnReview" href={row.value && row.value}>
						{row.value && row.value}
					</a>
				)
			},
			{
				Header: () => <span>Actions</span>,
				accessor: '_id',
				Cell: (row) => {
					let test = this.getJobTestInfo(job)[0];
					return (
						<div>
							<Link to={`/jobs/${job._id}/applicant/${row.value}`}>
								<i className="fa fa-eye" title="View Applicant Details" style={{ color: '#4563f8' }} />
							</Link>
							&nbsp;&nbsp;
							<button
								className="fa fa-trash-alt button-style"
								onClick={this.deleteUser.bind(this, job._id, row.value)}
								aria-hidden="true"
								type="button"
							/>
							<br />
							{(job.shortlistedApplicants.indexOf(row.value) < 0 && (
								<button
									className="fa fa-user-plus"
									title="shortlist User"
									onClick={this.shortlistUser.bind(this, job._id, row.value)}
									style={{ color: '#4563e8' }}
									type="button"
								/>
							)) || (
								<div>
									<i
										className="fa fa-check-circle fa-lg"
										title="Already shortlisted"
										style={{ color: 'rgb(70, 121, 21)' }}
									/>
									&nbsp;&nbsp;
									<div class="form-check">
										<label class="form-check-label">
											<input
												name={row.value}
												onChange={this.selectForInvitation.bind(this)}
												type="checkbox"
												className="form-check-input"
												checked={true && this.state.invitationList.includes(row.value)}
											/>
											Send
										</label>
									</div>
									{job.status === 'closed' ? (
										<div class="form-check">
											<label class="form-check-label">
												<input
													name={row.value}
													onChange={this.selectTopCandidates.bind(this)}
													type="checkbox"
													className="form-check-input"
													checked={true && this.state.topCandidatesList.includes(row.value)}
												/>
												Mark Top
											</label>
										</div>
									) : (
										''
									)}
								</div>
							)}
						</div>
					);
				}
			}
		];
		const showEmailInvitationModal = (
			<Modal visible={this.state.show} onClickBackdrop={this.handleClose}>
				<div className="modal-header">
					<h5 className="modal-title">
						<span className="text-info">Interview Invitation</span>
					</h5>
					<button type="button" className="close" onClick={this.handleClose} aria-label="Close">
						<span aria-hidden="true">x</span>
					</button>
				</div>
				<form noValidate onSubmit={this.sendInvitation.bind(this)}>
					<div className="modal-body">
						<div className="row">
							<div className="form-group container">
								<label>Email Body:</label>
								<ReactQuill
									name="body"
									onChange={this.handleInterviewTextBoxChange}
									value={this.state.defaultInterviewText}
								/>
								{/* <textarea
                  className="form-control"
                  name="body"
                  onChange={this.onChange.bind(this)}
                /> */}
							</div>
						</div>
					</div>
					<div className="modal-footer">
						<button type="submit" className="btn btn-info">
							Send Test/Interview Invitation
						</button>
					</div>
				</form>
			</Modal>
		);
		const showTestInvitationModal = (
			<Modal visible={this.state.showTest} onClickBackdrop={this.handleClose}>
				<div className="modal-header">
					<h5 className="modal-title">
						<span className="text-info">Send Test Link</span>
					</h5>
					<button type="button" className="close" onClick={this.handleClose} aria-label="Close">
						<span aria-hidden="true">x</span>
					</button>
				</div>
				<form noValidate onSubmit={this.sendTestInvitation.bind(this)}>
					<div className="modal-body">
						<div className="row">
							<div className="form-group container">
								<label>Email Body:</label>
								<ReactQuill
									name="body"
									onChange={this.handleTestTextBoxChange}
									value={this.state.defaultTestText}
								/>
							</div>
						</div>
					</div>
					<div className="modal-footer">
						<button type="submit" className="btn btn-info">
							Send Test Invitation
						</button>
					</div>
				</form>
			</Modal>
		);
		return (
			<div className="profiles">
				<div className="container">
					<div className="row">
						<div className="col-md-4">
							<h5>Color Codes</h5>
							<div className="mb-2" style={{ background: '#a5c5ad' }}>
								Test Invitation Sent to Candidate
							</div>
							<div style={{ background: '#007bff40 ' }}>Test email Invite sent</div>
						</div>
						<div class="col-md-8">
							<ul className="jobActions" style={{ float: 'right' }}>
								<li>
									{this.state.invitationList.length > 0 && (
										<button
											className="btn btn-default"
											onClick={this.handleShow.bind(this)}
											style={{ float: 'right' }}
										>
											Send Test/Interview Invitation
										</button>
									)}
								</li>
								<li>
									{this.state.invitationList.length > 0 && (
										<button
											className="btn btn-default"
											onClick={this.handleTestShow.bind(this)}
											style={{ float: 'right' }}
										>
											Send Test Link
										</button>
									)}
								</li>
								<li>
									{this.state.topCandidatesList.length > 0 && (
										<button
											className="btn btn-danger"
											onClick={this.deleteUserAndMarkTop.bind(this, job._id)}
											style={{ float: 'right' }}
										>
											Mark Top Candidates
										</button>
									)}
								</li>
							</ul>
						</div>
					</div>

					{showEmailInvitationModal}
					{showTestInvitationModal}
					<h3 className="display-5 text-center">Applicants</h3>
					{/* {this.props.tests.length > 0 && (
            <ReactTable
              className='-highlight '
              data={applicants}
              job={job}
              allUserJobs={allUserJobs}
              allUserTests={allUserTests}
              columns={columns}
              getTrProps={this.getTrProps}
              // getTdProps={this.getTdProps}
              filterable
            />
          )} */}
					<ReactTable
						className="-highlight "
						data={applicants}
						job={job}
						allUserJobs={allUserJobs}
						allUserTests={allUserTests}
						columns={columns}
						getTrProps={this.getTrProps}
						// getTdProps={this.getTdProps}
						filterable
					/>
				</div>
			</div>
		);
	}
}

Applicants.propTypes = {
	shortlistApplicant: PropTypes.func.isRequired,
	sendEmailInvitation: PropTypes.func.isRequired,
	sendTestInvitation: PropTypes.func.isRequired,
	getAllUserJobs: PropTypes.func.isRequired,
	getAllUserTests: PropTypes.func.isRequired,
	getTests: PropTypes.func.isRequired,
	changeStatus: PropTypes.func.isRequired
};
export default connect(
	(state) => ({
		allUserJobs: state.jobsData.allUserJobs,
		allUserTests: state.userTestsData.allUserTests,
		tests: state.testsData.tests
	}),
	{
		shortlistApplicant,
		removeApplicant,
		sendTestInvitation,
		sendEmailInvitation,
		getUserJobs,
		getAllUserJobs,
		getAllUserTests,
		getTests
	}
)(Applicants);
