import React, { Component } from "react";
import SignupHeader from "./ProfileHeader";
import SiteLogo from "../common/SiteLogo";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { createProfileStepFour } from "../../actions/authActions";
import ProfileFooter from "./ProfileFooter";
import isValid from "../../validation/profileValidation";

import { getUser } from "../../actions/authActions";

class ProfileStepFour extends Component {
  constructor() {
    super();
    this.state = {
      termsAndConditions: false,
      disabled: true
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    if (this.props.user.id) {
      this.props.getUser(this.props.user.id);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.profile) {
      if (!isValid(nextProps.profile, "4")) {
        // this.props.history.push("/profile-step-3");
      } else {
        this.setState({
          termsAndConditions: nextProps.profile.termsAndConditions
        });
      }
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.checked });
    // if (e.target.checked == true) {
    //   this.setState({ disabled: false });
    // } else {
    //   this.setState({ disabled: true });
    // }
  }

  onSubmit(e) {
    e.preventDefault();
    const ProfileStepFour = {
      email: this.props.user.email,
      termsAndConditions: !!this.state.termsAndConditions
    };
    this.props.createProfileStepFour(ProfileStepFour, this.props.history);
  }
  render() {
    return (
      <div>
        <SignupHeader tab={3} />
        <div className="signupStepSection signupbgTwo">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-10">
                {/* <SiteLogo /> */}
                <form noValidate onSubmit={this.onSubmit}>
                  <div className="loginForm">
                    <div className="signupPrivacyPolicy">
                      <h2> Privacy Policy </h2>
                      <p>
                        Nishat Chunian Group has built the Recruitment Portal
                        app as a Commercial app. This Service is provided by
                        Nishat Chunian Group and is intended for use as is.
                      </p>
                      <p>
                        This page is used to inform visitors regarding our
                        policies with the collection, use, and disclosure of
                        Personal Information if anyone has decided to use our
                        Service.
                      </p>
                      <p>
                        If you choose to use our Service, then you agree to the
                        collection and use of information in relation to this
                        policy. The Personal Information that we collect is used
                        for providing and improving the Service. We will not use
                        or share your information with anyone except as
                        described in this Privacy Policy.
                      </p>
                      <p>
                        The terms used in this Privacy Policy have the same
                        meanings as in our Terms and Conditions, which is
                        accessible at Recruitment Portal unless otherwise
                        defined in this Privacy Policy.
                      </p>

                      <h2>Information Collection and Use</h2>
                      <p>
                        For a better experience, while using our Service, we may
                        require you to provide us with certain personally
                        identifiable information, including but not limited to
                        Name, CNIC, Educational and Employer information. The
                        information that we request will be retained by us and
                        used as described in this privacy policy. The app does
                        use third party services that may collect information
                        used to identify you. Link to privacy policy of third
                        party service providers used by the app.
                      </p>
                      <h2>Log Data</h2>
                      <p>
                        We want to inform you that whenever you use our Service,
                        in a case of an error in the app we collect data and
                        information (through third party products) on your phone
                        called Log Data. This Log Data may include information
                        such as your device Internet Protocol (“IP”) address,
                        device name, operating system version, the configuration
                        of the app when utilizing our Service, the time and date
                        of your use of the Service, and other statistics.
                      </p>
                      <h2>Cookies</h2>
                      <p>
                        Cookies are files with a small amount of data that are
                        commonly used as anonymous unique identifiers. These are
                        sent to your browser from the websites that you visit
                        and are stored on your device's internal memory. This
                        Service does not use these “cookies” explicitly.
                        However, the app may use third party code and libraries
                        that use “cookies” to collect information and improve
                        their services. You have the option to either accept or
                        refuse these cookies and know when a cookie is being
                        sent to your device. If you choose to refuse our
                        cookies, you may not be able to use some portions of
                        this Service.
                      </p>
                      <h2>Service Providers</h2>
                      <p>
                        We may employ third-party companies and individuals due
                        to the following reasons:
                        <div className="container">
                          <ul style={{ "list-style-type": "disc" }}>
                            <li>To facilitate our Service;</li>
                            <li> To provide the Service on our behalf; </li>

                            <li>To perform Service-related services; or</li>

                            <li>
                              To assist us in analyzing how our Service is used
                            </li>
                          </ul>
                        </div>
                        We want to inform users of this Service that these third
                        parties have access to your Personal Information. The
                        reason is to perform the tasks assigned to them on our
                        behalf. However, they are obligated not to disclose or
                        use the information for any other purpose.
                      </p>
                      <h2>Security</h2>
                      <p>
                        We value the trust you place in us, by providing us your
                        Personal Information, thus we are striving to use
                        commercially acceptable means of protecting it. But
                        remember that no method of transmission over the
                        internet, or method of electronic storage is 100% secure
                        and reliable, and we cannot guarantee its absolute
                        security.
                      </p>

                      <h2>Links to Other Sites</h2>
                      <p>
                        This Service may contain links to other sites. If you
                        click on a third-party link, you will be directed to
                        that site. Note that these external sites are not
                        operated by us. Therefore, we strongly advise you to
                        review the Privacy Policy of these websites. We have no
                        control over and assume no responsibility for the
                        content, privacy policies, or practices of any
                        third-party sites or services.
                      </p>

                      <h2>Children’s Privacy</h2>
                      <p>
                        These Services do not address anyone under the age of
                        13. We do not knowingly collect personally identifiable
                        information from children under 13. In the case we
                        discover that a child under 13 has provided us with
                        personal information, we immediately delete this from
                        our servers. If you are a parent or guardian and you are
                        aware that your child has provided us with personal
                        information, please contact us so that we will be able
                        to do necessary actions.
                      </p>
                      <h2>Changes to This Privacy Policy</h2>
                      <p>
                        We may update our Privacy Policy from time to time.
                        Thus, you are advised to review this page periodically
                        for any changes. We will notify you of any changes by
                        posting the new Privacy Policy on this page. These
                        changes are effective immediately after they are posted
                        on this page.
                      </p>
                      <h2>Contact Us</h2>
                      <p>
                        If you have any questions or suggestions about our
                        Privacy Policy, do not hesitate to contact us at{" "}
                        <u>
                          <a href="mailto:hr@nishat.net" target="_top">
                            hr@nishat.net
                          </a>
                        </u>
                      </p>
                    </div>

                    <div className="form-check">
                      <label className="form-check-label">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          onChange={this.onChange}
                          name="termsAndConditions"
                          checked={this.state.termsAndConditions}
                        />
                        I agree to the NCG Privacy Policy
                      </label>
                    </div>

                    <div className="form-group">
                      <input
                        type="submit"
                        value="Next"
                        disabled={!this.state.termsAndConditions}
                        className={
                          this.state.termsAndConditions == false
                            ? "terms_disabled"
                            : ""
                        }
                      />
                    </div>
                  </div>
                </form>

                <ProfileFooter />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ProfileStepFour.propTypes = {
  getUser: PropTypes.func.isRequired,
  createProfileStepFour: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired
};

export default connect(
  state => ({
    user: state.auth.user,
    profile: state.auth.profile
  }),
  { getUser, createProfileStepFour }
)(ProfileStepFour);
