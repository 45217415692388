import SignupHeader from './ProfileHeader'
import SiteLogo from '../common/SiteLogo'
// import { Field, FieldArray, reduxForm } from "redux-form";
import AddEducation from './Education'
import AddCareer from './Career'
import React, { Component } from 'react'
import { getFormValues } from 'redux-form'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import isEmpty from '../../validation/is-empty'
import ProfileFooter from './ProfileFooter'
import { createProfileStepTwo, getUser } from '../../actions/authActions'
import { getAllEducations } from '../../actions/educationActions'
import { YEAR_RANGE } from '../../globals/variables'

class ProfileStepTwo extends Component {
  constructor () {
    super()
    this.state = {
      errors: {},
      totalExperience: ''
    }
  }
  onSubmit (e) {
    e.preventDefault()
    const education = this.props.education.education
    const career = this.props.career.career
    const totalExperience = this.state.totalExperience
    const educations = []
    education.forEach(edu => {
      let newEducation = {
        institute: edu.institute,
        fieldOfStudy: edu.fieldOfStudy,
        degree: edu.degree,
        cgpa: edu.cgpa,
        percentage: edu.percentage,
        from: edu.from || YEAR_RANGE[0],
        to: edu.to || YEAR_RANGE[0]
      }
      educations.push(newEducation)
    })
    const careers = []
    career.forEach(career => {
      let newCareer = {
        company: career.company,
        position: career.position,
        from: career.from || YEAR_RANGE[0],
        to: career.to || YEAR_RANGE[0],
        description: career.description,
        currentJob: career.currentJob
      }
      careers.push(newCareer)
    })
    const user = {
      email: this.props.auth.user.email,
      totalExperience: totalExperience,
      education: educations,
      career: careers
    }
    this.props.createProfileStepTwo(user, this.props.history)
  }
  componentWillReceiveProps (nextProps) {
    if (nextProps.auth.profile) {
      if (!this.state.totalExperience) {
        this.setState({
          totalExperience: nextProps.auth.profile.totalExperience
        })
      }
    }
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors
      })
    }
  }
  componentDidMount () {
    if (this.props.user.id) {
      this.props.getUser(this.props.user.id)
      this.props.getAllEducations()
    }
  }

  onChange (e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  render () {
    const { errors } = this.state
    const range = YEAR_RANGE
    return (
      <div>
        <SignupHeader tab={2} />
        <div className='signupStepSection'>
          <div className='container'>
            <div className='row align-items-center'>
              <div className='col-lg-6 col-md-10'>
                {/* <SiteLogo /> */}
                <form
                  onSubmit={this.onSubmit.bind(this)}
                  name='EducationAndCareerHistory'
                >
                  <div className='loginForm'>
                    <h3> Your Education </h3>
                    <p> Add your most recent qualification first</p>
                    <AddEducation errors={errors.education} range={range} />
                    <h3> Career History </h3>
                    <p>Add your Career history</p>
                    {/* <div className="addMoreFieldGroup col-sm-12"></div> */}
                    <div className='addMoreFieldGroup col-sm-12'>
                      <div className='form-group'>
                        <input
                          className='form-control'
                          type='number'
                          name='totalExperience'
                          value={this.state.totalExperience}
                          onChange={this.onChange.bind(this)}
                          placeholder='Overall Experience in Years'
                        />
                        {errors.totalExperience && (
                          <div className='is-invalid'>
                            {errors.totalExperience}
                          </div>
                        )}
                        <small>Write 0 if you have no Experience.</small>
                      </div>
                    </div>
                    <AddCareer errors={errors.career} range={range} />

                    <div className='form-group'>
                      <input type='submit' value='Next' />
                    </div>
                  </div>
                </form>
                <ProfileFooter />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

ProfileStepTwo.propTypes = {
  createProfileStepTwo: PropTypes.func.isRequired,
  education: PropTypes.object,
  career: PropTypes.object,
  errors: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  getUser: PropTypes.func.isRequired,
  getAllEducations: PropTypes.func.isRequired
}
const mapStateToProps = state => ({
  auth: state.auth,
  user: state.auth.user,
  education: getFormValues('AddEducation')(state),
  career: getFormValues('AddCareer')(state),
  // totalExperience: state.auth.profile.totalExperience,
  errors: state.errors
})

export default connect(
  mapStateToProps,
  { createProfileStepTwo, getUser, getAllEducations }
)(ProfileStepTwo)
