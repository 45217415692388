import React, { Component } from 'react'
import Spinner from '../common/Spinner'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { updateJob, getJob } from '../../actions/jobActions'
import { getTests } from '../../actions/testActions'
import { getDepartments } from '../../actions/departmentActions'
import { getEducations } from '../../actions/educationActions'
import { getEmploymentQuestions } from '../../actions/employmentQuestionsActions'
import { getCompanies } from '../../actions/companyActions'
import { connect } from 'react-redux'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import SelectListGroup from '../common/SelectListGroup'
import Navbar from '../layout/Navbar'
import 'react-datepicker/dist/react-datepicker.css'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import Select from 'react-select'
import { withAlert } from 'react-alert'

class EditJob extends Component {
  constructor () {
    super()
    this.state = {
      title: '',
      companies: [],
      description: '',
      education: '',
      skills: [],
      cgpa: '',
      percentage: '',
      experience: '',
      career_level: '',
      job_type: '',
      start_date: moment().format(),
      end_date: moment().format(),
      test: '',
      department: '',
      tests: [],
      departments: [],
      educations: [],
      employmentQuestions: [],
      location: '',
      conduct_test: false,
      // disabled: false,
      selectedEducations: null,
      selectedEmploymentQuestions: null,
      submitted: false,
      errors: {}
    }
    this.onChange = this.onChange.bind(this)
    this.handleTextBoxChange = this.handleTextBoxChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.onCheck = this.onCheck.bind(this)
    this.handleStartChange = this.handleStartDateChange.bind(this)
    this.handleEndChange = this.handleEndDateChange.bind(this)
  }

  handleTextBoxChange (value) {
    this.setState({ description: value })
  }

  onSubmit (e) {
    e.preventDefault()
    this.setState({ submitted: true })
    const educationsArray = this.state.selectedEducations.map(ed => {
      return {
        _id: ed.value
      }
    })

    const eqArray = this.state.selectedEmploymentQuestions.map(eq => {
      return {
        _id: eq.value
      }
    })

    const editJob = {
      title: this.state.title,
      company: this.state.company,
      description: this.state.description,
      education: this.state.education,
      skills: this.state.skills.join(),
      cgpa: this.state.cgpa,
      percentage: this.state.percentage,
      experience: this.state.experience,
      career_level: this.state.career_level,
      job_type: this.state.job_type,
      start_date: this.state.start_date,
      end_date: this.state.end_date,
      test: this.state.test,
      department: this.state.department,
      location: this.state.location,
      conduct_test: this.state.conduct_test,
      educations: educationsArray,
      employment_questions: eqArray
    }
    this.props.updateJob(
      this.props.match.params.id,
      editJob,
      this.props.history,
      this.props.alert
    )
  }

  onCheck (e) {
    this.setState({
      // disabled: !this.state.disabled,
      conduct_test: !this.state.conduct_test
    })
  }

  handleChange = selectedEducations => {
    this.setState({ selectedEducations })
    console.log(`Option selected:`, selectedEducations)
  }

  handleEqChange = selectedEmploymentQuestions => {
    this.setState({ selectedEmploymentQuestions })
    console.log(`Option selected:`, selectedEmploymentQuestions)
  }

  handleStartDateChange (date) {
    this.setState({
      start_date: date
    })
  }
  handleEndDateChange (date) {
    this.setState({
      end_date: date
    })
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors })
    }
    if (nextProps.job) {
      const job = nextProps.job
      if (Object.keys(job).length !== 0) {
        this.setState({
          title: job.title,
          company: job.company && job.company._id,
          description: job.description,
          education: job.education,
          skills: job.skills,
          cgpa: job.cgpa,
          percentage: job.percentage,
          experience: job.experience,
          career_level: job.career_level,
          job_type: job.job_type,
          start_date: job.start_date,
          end_date: job.end_date,
          test: job.test,
          department: job.department,
          location: job.location,
          conduct_test: job.conduct_test,
          selectedEducations:
            job.educations &&
            job.educations.map(ed => {
              return {
                label: ed.title,
                value: ed._id
              }
            }),
          selectedEmploymentQuestions:
            job.employment_questions &&
            job.employment_questions.map(eq => {
              return {
                label: eq.title,
                value: eq._id
              }
            })
        })
      }
    }
    if (nextProps.tests) {
      this.setState({ tests: nextProps.tests })
    }
    if (nextProps.departments) {
      this.setState({ departments: nextProps.departments })
    }
    if (nextProps.educations) {
      this.setState({ educations: nextProps.educations })
    }
    if (nextProps.employmentQuestions) {
      this.setState({ employmentQuestions: nextProps.employmentQuestions })
    }
    if (nextProps.companies) {
      this.setState({ companies: nextProps.companies })
    }
  }

  componentDidMount () {
    this.props.getJob(this.props.match.params.id)
    this.props.getDepartments()
    this.props.getTests()
    this.props.getEducations()
    this.props.getEmploymentQuestions()
    this.props.getCompanies()
  }

  // onChange(e) {
  //   this.setState({ [e.target.name]: e.target.value });
  // }

  onChange (e) {
    let tests = this.props.tests
    if (e.target.name == 'department') {
      tests = tests.filter(t => t.department == e.target.value)
    }
    if (e.target.name == 'career_level') {
      tests = tests.filter(t => t.level == e.target.value.toLowerCase())
    }
    this.setState({ [e.target.name]: e.target.value, tests: tests })
  }
  handleStartDateChange (date) {
    this.setState({
      start_date: date
    })
  }

  render () {
    const { errors } = this.state

    const {
      tests,
      departments,
      educations,
      employmentQuestions,
      companies
    } = this.state

    const formattedEducations = educations.map(ed => {
      return {
        label: ed.title,
        value: ed._id
      }
    })

    const formattedEmploymentQuestions = employmentQuestions.map(eq => {
      return {
        label: eq.title,
        value: eq._id
      }
    })

    const formattedCompanies = companies.map(com => {
      return {
        label: com.title,
        value: com._id
      }
    })

    const deptOptions = []
    for (var i = 0; i < departments.length; i++) {
      deptOptions.push({
        label: departments[i].title,
        value: departments[i]._id
      })
    }

    const testOptions = []
    for (var i = 0; i < tests.length; i++) {
      testOptions.push({
        label: tests[i].title,
        value: tests[i]._id
      })
    }

    return (
      <div>
        <Navbar />

        <div className='register'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-8 m-auto'>
                <h1 className='display-4 text-center'>Edit Job</h1>
                <p className='lead text-center'>Edit this Job</p>
                <form noValidate onSubmit={this.onSubmit}>
                  <div className='form-group'>
                    <label>Title</label>
                    <input
                      type='text'
                      className={classnames('form-control form-control-lg', {
                        'is-invalid': errors.title
                      })}
                      placeholder='Title'
                      name='title'
                      value={this.state.title}
                      onChange={this.onChange}
                    />
                    {errors.title && (
                      <div className='invalid-feedback'>{errors.title}</div>
                    )}
                  </div>
                  <SelectListGroup
                    placeholder='Department'
                    name='department'
                    value={this.state.department}
                    onChange={this.onChange}
                    options={deptOptions}
                    error={errors.department}
                    info='Select the department for this Job'
                  />

                  <SelectListGroup
                    placeholder='Company'
                    name='company'
                    value={this.state.company}
                    onChange={this.onChange}
                    options={formattedCompanies}
                    error={errors.company}
                    info='Select the company for this Job'
                  />
                  <label>Job Description</label>
                  <ReactQuill
                    value={this.state.description}
                    onChange={this.handleTextBoxChange}
                  />
                  {errors.description && (
                    <p className='description-error'>{errors.description} </p>
                  )}
                  <div className='form-group'>
                    <label>Skills</label>
                    <input
                      type='text'
                      className={classnames('form-control form-control-lg', {
                        'is-invalid': errors.skills
                      })}
                      placeholder='Skills'
                      name='skills'
                      value={this.state.skills}
                      onChange={this.onChange}
                    />
                    {errors.skills && (
                      <div className='invalid-feedback'>{errors.skills}</div>
                    )}
                  </div>
                  <div className='form-group'>
                    <label>CGPA</label>
                    <input
                      type='number'
                      className={classnames('form-control form-control-lg', {
                        'is-invalid': errors.cgpa
                      })}
                      placeholder='CGPA'
                      name='cgpa'
                      value={this.state.cgpa}
                      onChange={this.onChange}
                    />
                    {errors.cgpa && (
                      <div className='invalid-feedback'>{errors.cgpa}</div>
                    )}
                  </div>
                  <div className='form-group'>
                    <label>Percentage</label>
                    <input
                      type='number'
                      className={classnames('form-control form-control-lg', {
                        'is-invalid': errors.percentage
                      })}
                      placeholder='Percentage'
                      name='percentage'
                      value={this.state.percentage}
                      onChange={this.onChange}
                    />
                    {errors.percentage && (
                      <div className='invalid-feedback'>
                        {errors.percentage}
                      </div>
                    )}
                  </div>
                  <div className='form-group'>
                    <label>Experience</label>
                    <input
                      type='number'
                      className={classnames('form-control form-control-lg', {
                        'is-invalid': errors.experience
                      })}
                      placeholder='Experience'
                      name='experience'
                      value={this.state.experience}
                      onChange={this.onChange}
                    />
                    {errors.experience && (
                      <div className='invalid-feedback'>
                        {errors.experience}
                      </div>
                    )}
                  </div>
                  <div className='form-group'>
                    <label>Add Educational Qualifications for this job</label>
                    <Select
                      value={this.state.selectedEducations}
                      onChange={this.handleChange}
                      options={formattedEducations}
                      isMulti
                    />
                  </div>
                  <div className='form-group'>
                    <label>Select Employment Questions for this job</label>
                    <Select
                      value={this.state.selectedEmploymentQuestions}
                      onChange={this.handleEqChange}
                      options={formattedEmploymentQuestions}
                      isMulti
                    />
                  </div>
                  <div className='form-group'>
                    <label>Career Level</label>
                    <select
                      className={classnames('form-control form-control-lg', {
                        'is-invalid': errors.career_level
                      })}
                      placeholder='Career Level'
                      name='career_level'
                      value={this.state.career_level}
                      onChange={this.onChange}
                    >
                      <option value='' disabled>
                        Select career level
                      </option>
                      <option> Entry</option>
                      <option> Mid</option>
                      <option> Advanced</option>
                    </select>
                    {/* <input type="text" /> */}
                    {errors.career_level && (
                      <div className='invalid-feedback'>
                        {errors.career_level}
                      </div>
                    )}
                  </div>

                  <div className='form-group'>
                    <label>Job Type</label>
                    <select
                      className={classnames('form-control form-control-lg', {
                        'is-invalid': errors.job_type
                      })}
                      placeholder='Job Type'
                      name='job_type'
                      value={this.state.job_type}
                      onChange={this.onChange}
                    >
                      <option value='' disabled>
                        Select Job type
                      </option>
                      <option> Permanent</option>
                      <option> Contractual</option>
                    </select>
                    {/* <input type="text" /> */}
                    {errors.job_type && (
                      <div className='invalid-feedback'>{errors.job_type}</div>
                    )}
                  </div>
                  <div className='form-group'>
                    <label>Start Date</label>
                    <br />
                    <DatePicker
                      className={classnames('form-control form-control-lg', {
                        'is-invalid': errors.start_date
                      })}
                      selected={moment(this.state.start_date)}
                      onChange={this.handleStartChange}
                    />
                    {errors.start_date && (
                      <div className='invalid-feedback'>
                        {errors.start_date}
                      </div>
                    )}
                  </div>
                  <div className='form-group'>
                    <label>End Date</label>
                    <br />
                    <DatePicker
                      className={classnames('form-control form-control-lg', {
                        'is-invalid': errors.end_date
                      })}
                      selected={moment(this.state.end_date)}
                      onChange={this.handleEndChange}
                    />
                    {errors.end_date && (
                      <div className='invalid-feedback'>{errors.end_date}</div>
                    )}
                  </div>
                  <div className='form-group'>
                    <label>Location</label>
                    <input
                      type='text'
                      className={classnames('form-control form-control-lg', {
                        'is-invalid': errors.job_type
                      })}
                      placeholder='Location'
                      name='location'
                      value={this.state.location}
                      onChange={this.onChange}
                    />
                    {errors.location && (
                      <div className='invalid-feedback'>{errors.location}</div>
                    )}
                  </div>
                  <div className='form-check mb-4'>
                    <input
                      type='checkbox'
                      className='form-check-input'
                      name='current'
                      value={this.state.conduct_test}
                      checked={this.state.conduct_test}
                      onChange={this.onCheck}
                      id='conduct_test'
                    />
                    <label htmlFor='current' className='form-check-label'>
                      Conduct Test ?
                    </label>
                  </div>
                  <SelectListGroup
                    placeholder='Test'
                    name='test'
                    value={this.state.test}
                    onChange={this.onChange}
                    options={testOptions}
                    error={errors.test}
                    disabled={!this.state.conduct_test}
                    info='Select the test for this Job'
                  />
                  <button type='submit' className='btn btn-info btn-block mt-4'>
                    Save Job
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

EditJob.propTypes = {
  updateJob: PropTypes.func.isRequired,
  getJob: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  job: PropTypes.object,
  getTests: PropTypes.func.isRequired,
  tests: PropTypes.array.isRequired,
  getDepartments: PropTypes.func.isRequired,
  departments: PropTypes.array.isRequired,
  getEducations: PropTypes.func.isRequired,
  educations: PropTypes.array.isRequired,
  getEmploymentQuestions: PropTypes.func.isRequired,
  employmentQuestions: PropTypes.array.isRequired,
  getCompanies: PropTypes.func.isRequired,
  companies: PropTypes.array.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  job: state.jobsData.job,
  tests: state.testsData.tests,
  departments: state.departmentsData.departments,
  educations: state.educationsData.educations,
  employmentQuestions: state.employmentQuestionsData.employmentQuestions,
  companies: state.companiesData.companies
})

export default connect(
  mapStateToProps,
  {
    getJob,
    updateJob,
    getTests,
    getDepartments,
    getEducations,
    getEmploymentQuestions,
    getCompanies
  }
)(withAlert(EditJob))
